@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --secondary-color: 0, 109, 119;
  --tertiary-color: 12, 132, 143;
  --grad-one-color: 171, 206, 209;
  --grad-two-color: 221, 231, 179;
  --highlight-color: 26, 193, 163; 
  --darken-color: 0, 76, 83;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(12,132,143,0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(12,132,143,1);
  cursor: pointer;
} */

img {
  max-width: none !important;
}

img.derv {
  max-width: 800px !important;
}

html {
  margin: 0;
  height: 100%;
  font-size: 14px;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
