.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
} */

/* .card .css-njdtkf-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4px !important;
} */


.pred-gradient {
  background: -webkit-linear-gradient(left,#FF8484 0%, #FFA461 25%, #FFF278 50%, #C7DB78 75%, #7BC960 100%);
}

.tox-gradient {
  background: -webkit-linear-gradient(right,#FF8484 0%, #FFA461 25%, #FFF278 50%, #C7DB78 75%, #7BC960 100%);
}

.ad-gradient {
  background: -webkit-linear-gradient(left,#B5E0D7 0%, #79BFC9 25%, #4EA7BC 50%, #3582A4 75%, #2E5F8A 100%);
}




.darken:hover img, .darken-icons img {
  filter: brightness(0) saturate(100%) invert(26%) sepia(5%) saturate(1475%) hue-rotate(162deg) brightness(94%) contrast(98%);
  transition: all 0.3s ease-in-out;
}


.green img {
  filter: invert(26%) sepia(29%) saturate(3098%) hue-rotate(153deg) brightness(104%) contrast(101%);
}

.make-green:hover img {
  filter: invert(26%) sepia(29%) saturate(3098%) hue-rotate(153deg) brightness(104%) contrast(101%);
}

.tertiary img {
  filter: invert(36%) sepia(22%) saturate(3389%) hue-rotate(149deg) brightness(97%) contrast(91%);
}

.whiten img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(185deg) brightness(100%) contrast(102%);
}

.lighten:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(63deg) brightness(107%) contrast(102%);
}

.highlight:hover img {
  filter: invert(58%) sepia(95%) saturate(369%) hue-rotate(119deg) brightness(88%) contrast(91%);
}

.highlight-icon img {
  filter: invert(58%) sepia(95%) saturate(369%) hue-rotate(119deg) brightness(88%) contrast(91%);
}

.highlight:hover p {
  color: #1AC1A3;
}

.vis-box:hover div:first-child button:first-child img {
  filter: invert(58%) sepia(95%) saturate(369%) hue-rotate(119deg) brightness(88%) contrast(91%);
}

.vis-box:hover {
  border-color: #1AC1A3;
}

.vis-box:hover div:first-child button:first-child p {
  color: #1AC1A3;
}


.table-container {
  max-width: calc(100vw - 150px);
}


.table-container-expanded {
  max-width: calc(100vw - 320px);
}

.mini-table-container {
  max-width: calc(100vw - 105px);
}

.dashboard-container {
  max-width: calc(66vw - 80px);
}

.dashboard-container-expanded {
  max-width: calc(66vw - 100px);
}

.frozen-column {
  box-shadow: -3px 0 3px -1px rgba(0, 0, 0, 0.15); /* Adjusted shadow */
  transition: box-shadow 0.3s ease;

}

.frozen-first-column {
  box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.no-shadow {
  box-shadow: none !important;
}


.table-body:nth-child(odd), .table-body:nth-child(odd) .cell {
  background-color: #EDF0FF;
}

.table-body:nth-child(even), .table-body:nth-child(even) .cell {
  background-color: #ffffff;
}

.dark-table-body:nth-child(odd), .dark-table-body:nth-child(odd) .cell {
  background-color: #23262F;
}

.dark-table-body:nth-child(even), .dark-table-body:nth-child(even) .cell {
  background-color: #3C3F4E;
}

.table-body.selected, .table-body.selected .cell {
  background-color: rgba(12, 132, 143, 0.2) !important;
}

.table-inner-body, .table-inner-body .inner-cell {
  background-color: #F4F6FF;
}

.table-inner-body.selected, .table-inner-body.selected .inner-cell {
  background-color: rgba(12, 132, 143, 0.2) !important;
}

.dark-table-inner-body:nth-child(even), .dark-table-inner-body:nth-child(even) .inner-cell {
  background-color: #646A7B;
}



.dark-table-inner-body:nth-child(odd), .dark-table-inner-body:nth-child(odd) .inner-cell {
  background-color: #454E69;
}

.card-body:nth-child(odd){
  background-color: #F3F5FF;
}

.card-body:nth-child(even){
  background-color: #ffffff;
}

.dark-card-body:nth-child(odd) {
  background-color: #23262F;
}

.dark-card-body:nth-child(even) {
  background-color: #3C3F4E;
}


.table-inner-body:nth-child(odd), .table-inner-body:nth-child(odd) .inner-cell {
  background-color: #ffffff;
}

.inner-shadow {
  box-shadow: 0 20px 10px -20px rgba(0,0,0,0.35) inset, 0 -20px 10px -20px rgba(0,0,0,0.35) inset;
}

.top-only {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}


.disable-click {
  color: '#35424A';
  cursor: auto;
}

.disable-click:hover {
  color: '#35424A';
}

.enable-click {
  color: '#006D77';
  cursor: pointer;
} 

.enable-click:hover {
  color: '#0C848F';
}


.main {
  transition: margin-left .5s;
}

.popup-max-height {
  max-height: calc(100vh - 120px);
}

.field-popup-max-height {
  max-height: calc(100vh - 240px);
}

th, td {
  font-weight: 600 !important;
}

.css-13cymwt-control {
  border: 1px solid black !important;
}

.dashboard .css-13cymwt-control {
  border: 1px solid #006D77 !important;
}

.no-border .css-13cymwt-control {
  border: none !important;
}

.search .css-13cymwt-control, .search .css-t3ipsp-control {
  border-color: rgba(var(--secondary-color), 0.5) !important;
  color: white !important;
  border-radius: 0px 5px 5px 0px !important;
  background-color: rgba(var(--secondary-color), 0.5) !important;
  color: white !important;
  height: 100% !important;
}

.new-search .css-13cymwt-control, .new-search .css-t3ipsp-control {
  border-radius: 3px !important;
  height: 100% !important;
  border: 1px solid #006D77 !important;
}


.css-1u9des2-indicatorSeparator {
  opacity: 0;
}

.js-plotly-plot .plotly .modebar-group, .modebar {
  display: flex !important; 
  padding: 0 !important;
}

.react-tabs__tab-panel--selected {
  background-color: white !important;
  box-shadow: 0 4px 2px rgba(0,0,0,0.25);
  border-radius: 5px;
}

.dark-tabs {
  background-color: #3C3F4E !important;
}


.react-tabs__tab-panel--selected.no-shadow {
  box-shadow: none !important;
}

.react-tabs__tab-list {
  margin-bottom: 0px !important;
  border-bottom: none !important;
}

.react-tabs__tab--selected {
  border: none !important;
  color: rgba(var(--secondary-color), 1) !important;
}

.react-tabs__tab {
  padding: 10px 18px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #6b6b6b;
  text-wrap: nowrap !important;
}

.react-tabs__tab:hover {
  color: rgba(var(--secondary-color), 1) !important;
}

.prediction-tab-list {
  display: inline-flex !important;
}



.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 30.33333px;
  width: 10.33333px;
  height: 10.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.required:after {
  content: '*';
  color: red;
}

#overlay .side-menu {
  transition: transform 0.3s ease;
  transform: translateX(100%);
}

#overlay.open .side-menu {
  transform: translateX(0);
}

.toggle-btn:hover div {
  background-color: rgba(0, 109, 119, 0.4);
  transition: all 0.3s ease-in-out;
}

.toggle-btn div {
  background-color: rgba(0, 109, 119, 0.2);
}


/* #overlay .popup-menu {
  transition: all 0.3s ease;
  transform: translateY(50%);
  opacity: 0;
}

#overlay.open .popup-menu {
  transform: translateY(0);
  opacity: 1;
} */

.menu-button {
  width: 0px;
  transition: width 0.3s ease;
}

.menu-button.open {
  width: 100%;
}

 #navbar {
  width: 245px;
  transition: width 0.3s ease;
}

#navbar.shrink {
  width: 80px;
}




#navbar .hide p {
  opacity: 1;
  transition: all 0.3s ease;
}

#navbar.shrink .hide {
  width: 45px;
}

#navbar .hide {
  width: 210px;
  transition: width 0.3s ease;
}



#navbar.shrink .hide p {
  opacity: 0;
} 

#navbutton {
  transition: all 0.3s ease;
}

#navbar.shrink #navbutton {
  transform: rotate(180deg);
}

#protocol-table {
  border-spacing: 0 1em;
  border-collapse: separate;
}

.tour-box, .tutorial-box {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
}


.tour-box .shepherd-content, .tutorial-box .shepherd-content {
  padding: 16px;
}


.tour-box .shepherd-text, .tutorial-box .shepherd-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
}

.tour-box .shepherd-text div, .tutorial-box .shepherd-text div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.tour-box div h2, .tutorial-box div h2 {
  font-weight: 600;
  font-size: 18px;
  color:#006D77;
}

.tour-box div h1, .tutorial-box div h1 {
  font-size: 1.5rem;
  font-weight: 600;
  text-wrap: nowrap;
}

.tour-box p {
  text-align: center;
  font-weight: 500;
}

.tutorial-box p {
  text-align: left;
  font-weight: 500;
  width: 100%;
}

.tour-box p span, .tutorial-box p span {
  font-weight: bold;
}

.tour-next {
  padding: 7px 14px !important;
  border-radius: 5px;
  background: #006D77 !important;
  color: white !important;
  font-weight: 500 !important;
}


@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
